<template>
  <v-container
      style="height: calc(100vh - 60px); overflow-y: auto"
      fluid
      class="py-0"
  >
    <v-card>
      <v-card-title v-if="selectedExamination">
        <div>{{ selectedExamination.name }}</div>
      </v-card-title>
      <v-card-title v-else>Hiện tại không có kíp thi</v-card-title>
      <template v-if="selectedExamination">
        <v-card-text>
          <div class="subtitle">Thời gian bắt đầu: {{ selectedExamination.startTime }}</div>
          <div class="d-flex my-2 align-center">
            <v-text-field v-model="search"
                          label="Tìm kiếm" placeholder="Nhập tên phòng, giám thị" hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" small @click="getData" :loading="loading">Làm mới danh sách</v-btn>
          </div>
          <v-data-table :items="roomUsers" :headers="headers"
                        :search="search"
                        :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
                        :items-per-page="100"
                        :custom-sort="customSort"
                        :options="options">
            <template v-slot:item.loggedInAt="{ item }">
              <div v-if="!item.loggedInAt">
                <v-chip dark color="red">Chưa vào phòng</v-chip>
              </div>
              <div v-else>
                <v-chip dark color="green">{{ item.loggedInAt }}</v-chip>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import SimpleTable from "@/components/table/SimpleTable.vue";
import {getNearestExamination} from "@/api/examination";
import {monitoringAssistant} from '@/api/assistant'
import dayjs from 'dayjs'
import { get } from 'lodash'

export default {
  components: {
    SimpleTable
  },

  data() {
    return {
      options: {
        sortBy: ['loggedInAt'],
        sortDesc: [true]
      },
      headers: [
        {
          text: "Phòng",
          value: "room.name",
        },
        {
          text: "Giám thị",
          value: "user.name",
        },
        {
          text: "Thời gian vào phòng",
          value: "loggedInAt",
        },
      ],
      search: '',
      loading: false,
      selectedExamination: undefined,
      roomUsers: [],
      intervalGet: undefined
    };
  },

  computed: {},

  async created() {
    await this.getData()
    this.intervalGet = setInterval(async () => {
      await this.getData()
    }, 10000)
  },

  destroyed() {
    if (this.intervalGet) {
      clearInterval(this.intervalGet)
    }
  },

  methods: {
    async getData() {
      try {
        this.loading = true
        const {data: examination} = await getNearestExamination();
        if (examination) {
          examination.startTime = dayjs(examination.startTime).format("YYYY-MM-DD HH:mm")
          this.selectedExamination = examination
          const {data: roomUsers} = await monitoringAssistant({
            examinationId: this.selectedExamination.id
          })
          this.roomUsers = roomUsers.map(e => {
            if (e.loggedInAt) {
              e.loggedInAt = dayjs(e.loggedInAt).format("YYYY-MM-DD HH:mm")
            }
            return e
          })
        } else {
          this.selectedExamination = undefined
        }
      } finally {
        this.loading = false
      }
    },

    customSort(items, indexes, isDescs) {
      if (indexes.length === 0) return items
      const index = indexes[0]
      const isDesc = isDescs[0]
      items.sort((a, b) => {
        if (index === "user.name") {
          const nameAs = a.user.name.split(" ")
          const nameAf = nameAs.pop()
          const nameA = [nameAf, ...nameAs].join(" ")
          const nameBs = b.user.name.split(" ")
          const nameBf = nameBs.pop()
          const nameB = [nameBf, ...nameBs].join(" ")
          if (!isDesc) {
            return nameA.localeCompare(nameB, 'vi')
          } else {
            return nameB.localeCompare(nameA, 'vi')
          }
        } else {
          const valA = get(a, index)
          const valB = get(b, index)
          if (!isDesc) {
            if (valA == null) {
              return -1
            }
            return valA < valB ? -1 : 1;
          } else {
            if (valB == null) {
              return -1
            }
            return valB < valA ? -1 : 1;
          }
        }
      });
      return items
    }
  }
};
</script>

<style scoped>
.subtitle {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
</style>